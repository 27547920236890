@layer components {
  .btn {
    @apply relative grid grid-flow-col items-center justify-center gap-1 rounded-md leading-5 duration-150 ease-out;

    * {
      @apply pointer-events-none;
    }

    &-text {
      &:hover {
        .text {
          @apply underline;
        }
      }
    }

    &-primary {
      @apply bg-theme-red text-white;
    }

    &-secondary {
      color: #842700;
      background-image: linear-gradient(to bottom, #fffcc4 0%, #fda33e 100%);
    }

    &-secondary-2 {
      color: #fff;
      background-image: linear-gradient(to bottom, #fda33e 0%, #842700 100%);
    }

    &-gold {
      @apply bg-theme text-[#351D1D];
    }

    &:disabled,
    &.disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }
}
